.headerContainer {
	width: 100%;
	background-color: white;
	display: flex;
	justify-content: center;
}
.header {
	position: sticky;
	display: flex;
	top: 0;
	justify-content: space-between;
	align-items: center;
	border-bottom: 0.1 px solid gray;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 85%;
	z-index: 999;
}
.header_left {
	display: flex;
	align-items: center;
}
.header_left img {
	height: 40px;
	margin: 0 10px;
}

.header_search {
	padding: 10px;
	display: flex;
	align-items: center;
	border-radius: 5px;
	height: 22px;
	color: gray;
	background-color: #eef3f8;
}
.header_search > input {
	outline: none;
	border: none;
	background: none;
}
.header_right {
	display: flex;
}
