.feed {
	flex: 0.6;
	margin: 0 20px;
}
.feed__inputContainer {
	background-color: white;
	padding: 10px;
	padding-bottom: 20px;
	border-radius: 10px;
	margin-bottom: 20px;
}
.feed__input {
	border: 1px solid lightgray;
	border-radius: 30px;
	display: flex;
	padding: 10px;
	color: gray;
	padding-left: 15px;
}
.feed__input > form {
	display: flex;
	width: 100%;
}
.feed__input > form > input {
	border: none;
	flex: 1;
	margin-left: 10px;
	outline-width: 0;
	font-weight: 600;
}
.feed__input > form > button {
	display: none;
}
.feed__inputOptions {
	display: flex;
	padding: 10px;
	margin-top: 10px;
	justify-content: space-evenly;
}
