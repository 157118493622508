.inputOption {
    display: flex;
    align-items: center;
    margin-top: 15px;
    color:gray;
    padding:10px;
    cursor:pointer;
}
.inputOption:hover {
    cursor:pointer;
    background-color:whitesmoke;
    border-radius: 10px;
    color:black;
}
