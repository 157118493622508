.widgets {
	position: sticky;
	flex: 0.3;
	top: 80px;
	background-color: white;
	border-radius: 10px;
	border: 1px solid lightgray;
	height: fit-content;
	padding-bottom: 10px;
}
.widgets__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
}
.widgets__header > h2 {
	font-size: 20px;
	font-weight: 400;
}
.widgets__article {
	display: flex;
	padding: 10px;
	cursor: pointer;
}
.widgets__article:hover {
	background-color: whitesmoke;
}
.widgets__articleLeft {
	color: #0177b7;
	margin-right: 5px;
}
.widgets__articleLeft > .MuiSvgIcon-root {
	font-size: 15px;
}
.widgets__articleRight > p {
	color: gray;
	font-size: 12px;
}
.widgets__articleRight > h {
	font-size: 14px;
}
.widgets__articleRight {
	flex: 1;
}
