.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
}
.App::before {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(2, 0, 36, 0.4);
	background: linear-gradient(
		0deg,
		rgb(255, 226, 254) 0%,
		rgb(255, 255, 255) 50%,
		rgb(245, 244, 244) 100%
	);
	z-index: -1;
}
.app_body {
	display: flex;
	margin-top: 35px;
	width: 85%;
	/* max-width: 1200px; */
	/* margin-left: 20px;
	margin-right: 20px; */
}
