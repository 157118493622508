.sidebar {
	position: sticky;
	top: 80px;
	flex: 0.3;
	border-radius: 10px;
	text-align: center;
	height: fit-content;
}
.sidebar__avatar {
	margin-bottom: 10px;
}
.sidebar__top > h4 {
	color: gray;
	font-size: 12px;
}
.sidebar__top > h2 {
	font-size: 18px;
}
.sidebar__top {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid lightgray;
	border-bottom: 0;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background-color: white;
	padding-bottom: 10px;
}
.sidebar__top > img {
	margin-bottom: -20px;
	width: 100%;
	height: 60px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	object-fit: cover;
}
.sidebar__stat {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
}
.sidebar__stat > p {
	color: gray;
	font-size: 13px;
	font-weight: 600;
}
.sidebar__stats {
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid lightgray;
	background-color: white;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}
.sidebar__statNumber {
	font-weight: bold;
	color: #0a66c2 !important;
}
.sidebar__bottom {
	text-align: left;
	padding: 10px;
	margin-top: 20px;
	border: 1px solid lightgray;
	background-color: white;
	border-radius: 10px;
}
.sidebar__bottom > p {
	font-size: 13px;
	padding-bottom: 10px;
}
.sidebar__recentItem {
	display: flex;
	font-size: 13px;
	color: gray;
	font-weight: bolder;
	cursor: pointer;
	margin-bottom: 5px;
	padding: 5px;
}
.sidebar__recentItem:hover {
	background-color: whitesmoke;
	border-radius: 10px;
	cursor: pointer;
	color: black;
}
.sidebar__hash {
	margin-left: 10px;
	margin-right: 5px;
}
